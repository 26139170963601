
import React from 'react';
import "./game.scss";




class Game extends React.Component  {

    render() {
        return (
            <div className="game">
                {
                //<h3>{this.props.title}</h3>
                }
          
            </div>

        )
    }
}

export default Game